<template>
  <div>
    <div class="login-wrapper">
      <div class="container-fluid">
        <div class="row">
          <div class="col-md-12 col-lg-7 col-xl-5 p-0">
            <div class="form-wrapper left-panel position-relative">
              <div class="logo position-relative">
                <router-link
                  :to="{ name: 'anonymousRoute-home' }"
                  class="logo-image"
                >
                  <img
                    src="/img/digital-staging-logo.svg"
                    alt="Digital Staging"
                  />
                </router-link>
                <router-link :to="{ name: 'anonymousRoute-register' }">
                  <span class="lets-start">新規アカウント作成</span>
                </router-link>
              </div>
              <div
                class="login-banner-mobile d-xs-block d-sm-block d-md-block d-lg-none"
              >
                <img src="/img/login-banner-mobile.png" alt="Sign up Banner" />
              </div>
              <div class="login-wrapper">
                <div class="register">
                  <h2 class="font-weight-bold blue-text">ログイン</h2>
                  <form v-on:submit="login">
                    <div class="mt-4">
                      <p class="m-0 font-weight-bold">メールアドレス</p>
                      <input
                        id="email"
                        class="form-control shadow-1"
                        type="email"
                        minlength="2"
                        maxlength="100"
                        v-model="email"
                        v-on:keyup="formMessage = null"
                        placeholder="メールアドレスを入力してください"
                        required
                      />
                    </div>
                    <div class="mt-4">
                      <p class="m-0 font-weight-bold">パスワード</p>
                      <div class="d-flex">
                        <input
                          id="password"
                          class="form-control d-inline shadow-1"
                          type="password"
                          v-model="password"
                          v-on:keyup="formMessage = null"
                          minlength="2"
                          maxlength="100"
                          placeholder="パスワードを入力して下さい"
                          style="
                            border-top-right-radius: 0 !important;
                            border-bottom-right-radius: 0 !important;
                            border-right: 0;
                          "
                          required
                        />
                        <button
                          class="btn btn-ds transparent m-0 d-inline shadow-1"
                          type="button"
                          style="
                            height: 56px;
                            width: 56px;
                            border-top-left-radius: 0;
                            border-bottom-left-radius: 0;
                            border-top: 1px solid #ced4da;
                            border-bottom: 1px solid #ced4da;
                            border-right: 1px solid #ced4da;
                          "
                          v-on:click="showPasswordToggle('password')"
                          v-waves.light
                        >
                          <font-awesome-icon
                            :icon="{
                              prefix: 'fas',
                              iconName: passwordIcon,
                            }"
                          />
                        </button>
                      </div>
                    </div>
                    <b-alert
                      class="m-0 mt-3 light-grey-outline grey-text"
                      variant="warning"
                      show
                      v-if="formMessage !== null"
                    >
                      {{ formMessage }}
                    </b-alert>
                    <button
                      class="btn btn-ds mt-3 dark-blue white-text"
                      type="submit"
                      v-waves.light
                    >
                      サインイン
                    </button>
                    <div class="mt-3 login-options">
                      <p class="grey-text m-0">
                        アカウントをまだお持ちでない場合
                        <router-link :to="{ name: 'anonymousRoute-register' }">
                          <span>こちらからご登録ください</span>
                        </router-link>
                      </p>
                      <p class="m-0">
                        <router-link
                          :to="{ name: 'anonymousRoute-reset-password' }"
                        >
                          <span>パスワードをお忘れですか？</span>
                        </router-link>
                      </p>
                    </div>
                    <div class="separator mt-4 mb-4 grey-text font-weight-bold">
                      or
                    </div>
                    <button
                      class="btn btn-ds w-100 white grey-text grey-outline border-radius-5"
                      v-waves.light
                      type="button"
                      v-on:click="googleLogin"
                    >
                      <img
                        class="mr-2"
                        src="/img/icons/ico-google.svg"
                        alt="Google"
                        style="height: 22px"
                      />
                      <span>Googleでサインイン</span>
                    </button>
                  </form>
                </div>
              </div>
              <div class="clearfix"></div>
            </div>
          </div>
          <div
            class="col-md-12 col-lg-5 col-xl-7 p-0 d-none d-lg-block banner-wrap"
          >
            <img src="/img/login-banner.png" alt="Sign up Banner" />
          </div>
        </div>
      </div>
    </div>
    <main-footer></main-footer>
  </div>
</template>

<script>
// Import Vuex/Store
import { mapGetters } from 'vuex';

// Import Services
import {
  globalService_Login,
  globalService_GoogleLogin,
  globalService_GetCurrentUserInfo,
} from '../services/global';

// Import Custom Components
import mainFooter from '../components/customer/Main-Footer.vue';

export default {
  metaInfo() {
    return {
      title: 'Digital Staging - Log In',
    };
  },

  components: {
    'main-footer': mainFooter,
  },

  data() {
    return {
      email: null,
      password: null,
      passwordIcon: 'eye-slash',
      formMessage: null,
    };
  },

  computed: {
    ...mapGetters(['currentUser', 'isLoggedIn']),
  },

  methods: {
    showPasswordToggle(element) {
      let _element = document.querySelector(`#${element}`);

      if (_element.getAttribute('type') === 'password') {
        _element.setAttribute('type', 'text');

        this.passwordIcon = 'eye';
      } else {
        _element.setAttribute('type', 'password');

        this.passwordIcon = 'eye-slash';
      }
    },

    login(e) {
      let _this = this;

      e.preventDefault();

      _this.formMessage = null;

      _this.$store.state.modalLoaderMessage = 'サインイン中';
      _this.$bvModal.show('modal-loader');

      globalService_Login({ email: _this.email, password: _this.password })
        .then((response) => {
          let accessToken = response.data.result.access_token;

          globalService_GetCurrentUserInfo({ token: accessToken })
            .then((_response) => {
              let role = _response.data.result.user_role;

              if (role.role_id === 4) {
                localStorage.setItem('_accessToken', accessToken);

                _this.$store.state.isLoggedIn = true;

                _this.$router.push({
                  name: 'userRoute-dashboard',
                });
              } else {
                _this.formMessage = `このアカウントは管理者用です。管理者用サインインページからサインインしてください。`;
              }

              setTimeout(() => {
                _this.$bvModal.hide('modal-loader');
              }, 500);
            })
            .catch((_error) => {
              console.log(_error);

              setTimeout(() => {
                _this.$bvModal.hide('modal-loader');
              }, 500);

              _this.formMessage = `メールアドレスかパスワードが間違っています。再度お試しください。`;
            });
        })
        .catch((error) => {
          console.log(error);

          setTimeout(() => {
            _this.$bvModal.hide('modal-loader');
          }, 500);

          _this.formMessage = `メールアドレスかパスワードが間違っています。再度お試しください。`;
        });
    },

    async googleLogin() {
      try {
        let _this = this;
        let googleUser = await _this.$gAuth.signIn();

        if (googleUser) {
          if (googleUser.isSignedIn()) {
            _this.formMessage = null;

            _this.$store.state.modalLoaderMessage = 'サインイン中';
            _this.$bvModal.show('modal-loader');

            globalService_GoogleLogin({
              at: googleUser.getAuthResponse().access_token,
            })
              .then((response) => {
                let data = response.data.result;

                localStorage.setItem('_accessToken', data.token.access_token);

                if (response.data.result.is_newly_registered) {
                  _this.$router.push({
                    name: 'anonymousRoute-register-profile',
                  });
                } else {
                  _this.$router.push({
                    name: 'userRoute-dashboard',
                  });
                }

                setTimeout(() => {
                  _this.$bvModal.hide('modal-loader');
                }, 500);
              })
              .catch((error) => {
                console.log(error);

                _this.formMessage = `
                  The email used to sign in to Google may be already registered to our system.
                  Please try using another email to sign in to Google or try logging into our system using email and password.
                `;

                setTimeout(() => {
                  _this.$bvModal.hide('modal-loader');
                }, 500);
              });
          } else {
            console.log('wew');
          }
        } else {
          return null;
        }
      } catch (error) {
        console.error(error);

        return null;
      }
    },
  },
};
</script>

<style scoped>
.login-wrapper .form-wrapper {
  padding: 42px;
  height: 100vh;
}

@media (max-width: 991.98px) {
  .login-wrapper .form-wrapper {
    height: auto;
  }
}

.login-wrapper .form-wrapper .lets-start {
  background-color: #00adee;
  color: #fff;
  border-radius: 25px;
  position: absolute;
  right: -178px;
  padding: 15px 12px;
  width: 175px;
  text-align: center;
  height: 53px;
  margin: 5px 0;
  z-index: 1;
}

.login-wrapper .form-wrapper .login-wrapper {
  padding: 40px 40px 10px 140px;
}

.login-wrapper .form-wrapper .login-wrapper .title-form {
  font-weight: 500;
}

.login-wrapper .form-wrapper .login-wrapper form .form-group {
  position: relative;
}

.login-wrapper .form-wrapper .login-wrapper form .btn-google {
  background: #ffffff 0% 0% no-repeat padding-box;
  border: 1px solid #bfbebe;
  border-radius: 100px;
  color: #707070;
}

.login-wrapper .form-wrapper .login-wrapper form .option-links p a,
.login-wrapper .form-wrapper .login-wrapper form .option-links a {
  color: #034ea1;
  text-decoration: underline !important;
}

.login-wrapper .form-wrapper .form-footer {
  padding: 10px 40px 10px 140px;
  position: absolute;
  margin: 1em auto;
  overflow: hidden;
  bottom: 0;
  width: 80%;
  margin: auto;
  left: 0;
  bottom: 0;
  right: 0;
}

.login-wrapper .form-wrapper .form-footer ul {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  justify-content: space-between;
  margin-left: -1px;
  list-style: none;
  padding-left: 0;
}

.login-wrapper .form-wrapper .form-footer ul li {
  flex-grow: 1;
  flex-basis: auto;
  margin: 0.25em 0;
  padding: 0 1em;
  text-align: center;
  border-left: 1px solid #ccc;
}

.login-wrapper .form-wrapper .form-footer ul li:first-child {
  border-left: 0 !important;
}

.login-wrapper .form-wrapper .form-footer ul li a {
  text-decoration: underline !important;
  color: #9b9b9b;
}

.login-wrapper .banner-wrap {
  height: 100vh;
  overflow: hidden;
  padding: 0;
}

.login-wrapper .banner-wrap img {
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
  object-fit: cover;
  -o-object-position: left center;
  object-position: left center;
}

@media (max-width: 991.98px) {
  .login-wrapper .form-wrapper {
    padding: 0 !important;
  }

  .login-wrapper .form-wrapper .logo {
    padding: 20px;
  }

  .login-wrapper .form-wrapper .logo .logo-image img {
    width: 172px;
  }

  .login-wrapper .form-wrapper .logo .lets-start {
    right: 5px !important;
    padding: 8px 0;
    height: auto;
    margin: 0;
    font-size: 10px;
    width: 110px;
  }

  .login-wrapper .form-wrapper .login-wrapper {
    padding: 20px;
    border-top-right-radius: 25px;
    border-top-left-radius: 25px;
    position: relative;
    top: -35px;
    background: #fff;
  }

  .login-wrapper .form-wrapper .login-wrapper .register h2{
    font-size: 25px;
  }

  .login-wrapper .form-wrapper .form-footer {
    padding: 20px;
    position: relative !important;
    width: 100%;
  }

  .login-wrapper .form-wrapper .login-banner-mobile img {
    width: 100%;
    height: 251px;
    -o-object-fit: cover;
    object-fit: cover;
    -o-object-position: center;
    object-position: center;
  }
}

@media (min-width: 992px) and (max-width: 1199.98px) {
  .login-wrapper .form-wrapper {
    padding: 10px 10px 10px 42px !important;
  }
  .login-wrapper .form-wrapper .login-wrapper,
  .login-wrapper .form-wrapper .form-footer {
    padding: 40px 10px 10px 0 !important;
  }
}

@media (min-width: 992px) and (max-width: 1399.98px) {
  .login-wrapper .form-wrapper {
    padding: 10px 10px 10px 42px !important;
  }
  .login-wrapper .form-wrapper .login-wrapper,
  .login-wrapper .form-wrapper .form-footer {
    padding: 40px 10px 10px 40px !important;
  }
  .login-options p {
    font-size: 12px;
  }
}
.separator {
  display: flex;
  align-items: center;
  text-align: center;
  font-size: 16px;
  font-weight: 400;
}

.separator::before,
.separator::after {
  content: '';
  flex: 1;
  border-bottom: 0.5px solid #b8b8b8;
}

.separator:not(:empty)::before {
  margin-right: 8px;
}

.separator:not(:empty)::after {
  margin-left: 8px;
}
.login-options p {
  font-style: normal;
  letter-spacing: 0px;
  opacity: 1;
  font-size: 14px;
}
@media (min-width: 1399px) and (max-width: 1590px) {
  .login-options p {
    font-size: 10px;
  }
}
</style>
